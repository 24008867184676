import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Smartphone Integration",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Smartphone_Integration/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker Smartphone Integration' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Setup/Smartphone_Integration/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Smartphone_Integration/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-smartphone-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-smartphone-integration",
        "aria-label": "iobroker smartphone integration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Smartphone Integration`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`ioBroker offers adapter for 3 different smartphone notification apps - `}<a parentName="p" {...{
        "href": "https://www.pushbullet.com"
      }}>{`Pushbullet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.pushsafer.com"
      }}>{`Pushsafer`}</a>{` and `}<a parentName="p" {...{
        "href": "https://pushover.net"
      }}>{`Pushover`}</a>{`. All of them work pretty much the same. We want to show the basic setup here, based on two of them.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pushover"
        }}>{`Pushover`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pushsafer"
        }}>{`Pushsafer`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "pushover",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pushover",
        "aria-label": "pushover permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pushover`}</h2>
    <p>{`First search for Pushover inside your smartphones app store and install the app. Please create an account and set a login.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d41eff499523b49d461a6af26edadf5/1a144/Pushover_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUD/8QAFgEBAQEAAAAAAAAAAAAAAAAABAEC/9oADAMBAAIQAxAAAAGvuXMoIN//xAAYEAADAQEAAAAAAAAAAAAAAAABAgMAEf/aAAgBAQABBQKRZkJ5rWdaf//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhEx/9oACAEDAQE/AVg5Oz//xAAYEQADAQEAAAAAAAAAAAAAAAAAAQIRMf/aAAgBAgEBPwF9JlYf/8QAGxABAAAHAAAAAAAAAAAAAAAAAQACEBEhUXH/2gAIAQEABj8CF1S0rjkf/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFhkf/aAAgBAQABPyGc2duiErvg8RQh/9oADAMBAAIAAwAAABAD3//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QZqy9p//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxDaQVL/xAAbEAEAAgIDAAAAAAAAAAAAAAABESEAMVFhsf/aAAgBAQABPxBGGE8mV1rJmw1SfMNjKAibOyc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d41eff499523b49d461a6af26edadf5/e4706/Pushover_01.avif 230w", "/en/static/1d41eff499523b49d461a6af26edadf5/d1af7/Pushover_01.avif 460w", "/en/static/1d41eff499523b49d461a6af26edadf5/b6582/Pushover_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d41eff499523b49d461a6af26edadf5/a0b58/Pushover_01.webp 230w", "/en/static/1d41eff499523b49d461a6af26edadf5/bc10c/Pushover_01.webp 460w", "/en/static/1d41eff499523b49d461a6af26edadf5/87ca7/Pushover_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d41eff499523b49d461a6af26edadf5/e83b4/Pushover_01.jpg 230w", "/en/static/1d41eff499523b49d461a6af26edadf5/e41a8/Pushover_01.jpg 460w", "/en/static/1d41eff499523b49d461a6af26edadf5/1a144/Pushover_01.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d41eff499523b49d461a6af26edadf5/1a144/Pushover_01.jpg",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then go to the `}<strong parentName="p">{`Adapters`}</strong>{` tab in ioBroker install the Pushover Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a66c7ad841a37a2449628981cad030e/e9beb/Pushover_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZUlEQVQY0z3ISUsCURwA8PkyHfoeQV2DMIjqUBR0qkOdKrp1CCLI6lLQYqYo2kaLJZq2QoIt5sw4TTrm9Oa9939vHMcFS6xL9Tv+hEX30dTCZsf42tBecWSfD+6ao/t4+VR0X71txt+2YspKJNcbLDp8Vre/6PCZfX4+EDT7A7xzXRO8x/Elz6ljxjUbtSYO6eQJmz7G8yHNHdMOr9VwCl095jwhyRN+9Z4r7lh++555EmwnwTdukNDa1tPSPtw1NpfRzeeCreCKqNsPhaqYBUXWqFX9srmVTjayUl19sdV0o17++qzUS+z7syI417adqy6X/0BTJC31hJQMEKNsmUWTEUqAUso44SaioOXziAKoWZRKFwwCjAt7cmMn3TzLN18ubpOBAyUSfdc/gDFsYFEUJVGSJZkQTDDOqWLBQBCO6L5gzsAMmHCZ4Xda7RnVGMGAECGEwi/65z+AUiiVoGwzAGDsB91PIuGIXn0NAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a66c7ad841a37a2449628981cad030e/e4706/Pushover_02.avif 230w", "/en/static/2a66c7ad841a37a2449628981cad030e/d1af7/Pushover_02.avif 460w", "/en/static/2a66c7ad841a37a2449628981cad030e/b6582/Pushover_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a66c7ad841a37a2449628981cad030e/a0b58/Pushover_02.webp 230w", "/en/static/2a66c7ad841a37a2449628981cad030e/bc10c/Pushover_02.webp 460w", "/en/static/2a66c7ad841a37a2449628981cad030e/87ca7/Pushover_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a66c7ad841a37a2449628981cad030e/81c8e/Pushover_02.png 230w", "/en/static/2a66c7ad841a37a2449628981cad030e/08a84/Pushover_02.png 460w", "/en/static/2a66c7ad841a37a2449628981cad030e/e9beb/Pushover_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a66c7ad841a37a2449628981cad030e/e9beb/Pushover_02.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will be asked for a Group Key and a Token that we need to get from the Pushover website: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://pushover.net/login`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13d68428c68039dbdff0e357e4a76121/e9beb/Pushover_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB5ElEQVQoz23LzUsUYRwH8Pkn+he6de7coUtgQXQSJJKwSCrx5qlL0D0jqFBEkVw1LXeXpZIOEu7O7sF0cWZnX+bF3X3mefs9865p6zOxbnoIv3z48r18lVcz6++Xvw5NTj/8RJ8XosfZYDIP777rn1VzrdTKVVoL2+1H2WBs45+n+XCiED3Z8IYXbOXF6/m3i/m7UzPjK+h+Rgwv+SMZb2yZPFtn42t8dImMLOKhOX7h9hy/M89vzbIHH7Fy5dqNqzdHr9+bmMq5Lze9QtnMVdqr286Xcjvz01opk+yul9uF/Lncmewv2DKE8m3zx1axoqrqbMld3aGurdtWw2xqbbthNjQgneMITiI4ieFPLPqSs47gKASljg9x0gtP071Gs7S3X6nbmit0LDQXdCyqXe5gLoRHGEcuRi7uIhdhQihjHJQPajBdjN8Uo3rT6jg2QegwDI/iaOB3EidxlCQJQkjb1wyjVqsZmqY5juN5nsI8v9frpWlq2Ac7dbPaso0uriM6YCDapkwAcM4ppYSQQTPGAEA55kLK9FRKw3R066DaaDURdpiwKdgULAoYROD7QggA6LeAwe6fPcKklKmUuqahbsexTOAsDoILYeD75+f/ovhxImWapikAMMYpo4xfErgsfwEkLPa+aTiFBgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13d68428c68039dbdff0e357e4a76121/e4706/Pushover_03.avif 230w", "/en/static/13d68428c68039dbdff0e357e4a76121/d1af7/Pushover_03.avif 460w", "/en/static/13d68428c68039dbdff0e357e4a76121/b6582/Pushover_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13d68428c68039dbdff0e357e4a76121/a0b58/Pushover_03.webp 230w", "/en/static/13d68428c68039dbdff0e357e4a76121/bc10c/Pushover_03.webp 460w", "/en/static/13d68428c68039dbdff0e357e4a76121/87ca7/Pushover_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13d68428c68039dbdff0e357e4a76121/81c8e/Pushover_03.png 230w", "/en/static/13d68428c68039dbdff0e357e4a76121/08a84/Pushover_03.png 460w", "/en/static/13d68428c68039dbdff0e357e4a76121/e9beb/Pushover_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13d68428c68039dbdff0e357e4a76121/e9beb/Pushover_03.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Login with the account you created inside your smartphone app:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/e9beb/Pushover_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABfUlEQVQoz6XQy27TQBSAYe+T2OTyHsmDoC7KOnUWoUZeuaQhouv2FepckFjyHixZIISQEKxASAXFpEns8SW+/dUMdRWkSCw40qczOnPOaGa0J8fHPD46wrFtXo8nLM5fKK+cEbPLK67nC1zX5dqd4k6lGdPZvrmqz+YLXl5coHW7XXq9Ho+aTeq6TsMwFKPZpN3p0JK53aYj160WhmGg67rSaDSo1WrU63VF1rR+v49kmiaDwUAxT044fXrK87MzHMdhNBoxmUwYj8fYto1lWVjWM4bD4d9zponGfRRFgRAhQeDzP6HleY6UZRlpmqr8x440FaTZvlDtVf2HaGVZUpEhhOC3tyKKV/z4+ZbV5h1p8Ykk/8gu/0xZpqpvf26fVm1WIdfb7ZYo3hGICO/WZ3nP2wjy4h9Prg6T102SRMnSHd4m5MO3NX4YE8UJIkoI4+Sh5xD5ZQ8HyoLv+/h+QJqEvP+65PLNF77f3PLLW3OzXBMEASIIVD4kiiLuAMr8S9qhT574AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/e4706/Pushover_04.avif 230w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/d1af7/Pushover_04.avif 460w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/b6582/Pushover_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/a0b58/Pushover_04.webp 230w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/bc10c/Pushover_04.webp 460w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/87ca7/Pushover_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/81c8e/Pushover_04.png 230w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/08a84/Pushover_04.png 460w", "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/e9beb/Pushover_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7dc429e22cd8e99aed5e7a81f07e39d1/e9beb/Pushover_04.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to `}<strong parentName="p">{`Apps&Plugins`}</strong>{` and choose to `}<em parentName="p">{`Create a New Application / API Token`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/e9beb/Pushover_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYklEQVQY03XETUvCYADA8X0FwT0uPXjoILZNgxBfQbsEgZ7EuxB076N06GN0Fk3xZGzO+TIVFGylc3M+U6ebxrC5RR3KDv3480dyuVwmk8nn84VCsVKtlsrl4lOlVK4812oNimYomqLrVJ2hmQbNfL/O/ITgOJ5Op7PZbDQaDUciyWTqMpWKxOM34djd1fVtLJEIhS5CXwiCIEkyeARxOBwej8fr9aIo6nQ6gQsA4EIBOEXRhB9PECR+5g8GzwNkgCBwn8+HYZjb7T7BMAAAMhqNmk2WZVmO41qtdqfTaTZbDZpusGy72x0Mh91er9/vTwVhPpclSRofQSzLgooiilMIlTmEwlSQoSKoqgihMJksFwtJmvH8i2EYtm1b9h+IaZrrY7quTSbK/cO6zam73UpVNU3b6rq60Reasf8wbesXcjgcZHnGv72OeF4Sp8vtHs5W4mNBHIyX7we4MVTdsP/xCf/kGvIjrLcgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/e4706/Pushover_05.avif 230w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/d1af7/Pushover_05.avif 460w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/b6582/Pushover_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/a0b58/Pushover_05.webp 230w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/bc10c/Pushover_05.webp 460w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/87ca7/Pushover_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/81c8e/Pushover_05.png 230w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/08a84/Pushover_05.png 460w", "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/e9beb/Pushover_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e8e51e4cab1e3de3b73bf3aef5bdce07/e9beb/Pushover_05.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Create the Application:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/e9beb/Pushover_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABwklEQVQoz5XQzY7aMBAH8DzAFpAa0fTAoXvgfatd4AHIBTiVXdRrVfXYHnpY9bhL43wQJOJ4xvbYMdkqSakQ6krtT5HtifNPxvHG4/FoNBoMBsPh0Pf9175/ff0ueBMEwdsgCHzff9W6urrq9frdut8Y9Hp9b9qazWa3t5PJdDqZTMMwXK/XH9brzWZzf3+3Wq2Wy+VisQjDcD6fd2PHc84ZMkSklLLWEpG19vnfeBFLkjTL0izLdo18l2YpYzFjLIpYnOYsTuIG6+4wFidJ8jOK8jz3mv18z4uCc/78nzyjtTFUlqUQgoicc8fjsa7rP0+cry/D3VRVldI6jmMhBJxIxGaUUiKWJce2BABEFEJIKZuwMUZKaa0FgOPL/v5l59zhcGhfpqrKvaQ+uWzbOaeVLooD5xxAnDrErsN2xq42xlyGDREAPm23EYvzfK8k2haRppah5r+attStuq69syPUTkOlBUosDHEBZVlyUEUJnItCyEIoLhSZ35qwMUZrbQ0VIL88pJ8esvgpwa/fRL4HiYAaUAFKkFqgBqnplCYiTynVnIRUtCtnd483H9mPz9/N+xt83KqqUhKVkqohu+vcL0tS3bmPE8xgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bd5e66cacfb34417be13b3ea8025ee1/e4706/Pushover_06.avif 230w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/d1af7/Pushover_06.avif 460w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/b6582/Pushover_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3bd5e66cacfb34417be13b3ea8025ee1/a0b58/Pushover_06.webp 230w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/bc10c/Pushover_06.webp 460w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/87ca7/Pushover_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bd5e66cacfb34417be13b3ea8025ee1/81c8e/Pushover_06.png 230w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/08a84/Pushover_06.png 460w", "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/e9beb/Pushover_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3bd5e66cacfb34417be13b3ea8025ee1/e9beb/Pushover_06.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will now get the `}<strong parentName="p">{`API Token`}</strong>{` - select and copy it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f7debf68a423d42bd6aef613974b1839/e9beb/Pushover_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHUlEQVQY012OXW6CQBSFWYkNM8SkJhV0OzzUB2YNLsOt1LaIGGwkGGOrEtlBWx601NIqwgjz1yAPjb35cpObe87JkSBQWlobAgiBUlfqQIYtra02VfVGVZta47qhQAWCEiBDIAMow/MJa7UryfGG5uRu6D2MPHPoPQ4mfWdmuQvHmVlPz6Px3LanpjOz7PPLcu8Hbrkttz+e2lLOTgf8fcD7OPncZzHlORVFIU6YpRk9ZjQlPGeCMEG4oBWEFyk7UU6lY3J8e33/2Ea7r59dnGQ541z8DS/hrIJXkIKlmBDCJIxxGIZLf7301y9+MF+sVis/CILNdsPPYl4F/KcMloQQURR1u91O5xYhAxkGQkjX9V6vVzovalwUEkL8AtKCEJVrcZTKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7debf68a423d42bd6aef613974b1839/e4706/Pushover_07.avif 230w", "/en/static/f7debf68a423d42bd6aef613974b1839/d1af7/Pushover_07.avif 460w", "/en/static/f7debf68a423d42bd6aef613974b1839/b6582/Pushover_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f7debf68a423d42bd6aef613974b1839/a0b58/Pushover_07.webp 230w", "/en/static/f7debf68a423d42bd6aef613974b1839/bc10c/Pushover_07.webp 460w", "/en/static/f7debf68a423d42bd6aef613974b1839/87ca7/Pushover_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7debf68a423d42bd6aef613974b1839/81c8e/Pushover_07.png 230w", "/en/static/f7debf68a423d42bd6aef613974b1839/08a84/Pushover_07.png 460w", "/en/static/f7debf68a423d42bd6aef613974b1839/e9beb/Pushover_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f7debf68a423d42bd6aef613974b1839/e9beb/Pushover_07.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to ioBroker and copy it into your Pushover Adapter Configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e6eddc1a5e462ccd261548166cff4e42/e9beb/Pushover_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtElEQVQoz33P0WrTUBzH8byDT+AD+AI+gd6JF94o09ZNRAU3e+WVirvxYncqCA68mbTF1S4QVmUoc93QrV0NbZM265YtTZo05/8/5yRN0k6RI6u4oYgfvrc/+P+lsxfSyqfazJNXl55Xp98NJ4v8nuy9XGll183s+u5C2bwj04lFni7wVIFfK/DJt/y2HNwosisLXenU6TPp+3Pnb86mXmzdkqNMETIy3i3SmSU6lcdUFq7n/m4qBxOv4dEykc5dvHw18zg1/eBhvvJ0gzcbDbWub9dUtd5sNHUfqOej20fX/6Oej32CEuNhnAyFEJoDJXVXt12TRXs0NOlAc8mOR4BSYIyeYDgGiNL8Z5zf5M8qh18+VLzVj9UlZTWbL+cXy7k33mY11tpxXfNqqm4YRtto6a1Op0MAfu2lto0WGYTfhLljNr821tY2ZKWklFaU5fea1u5ZTmDbZP+gd8RxHMd1XTgefwf8kSRCCNv363sHRtfxwpgkI5KMujSwkbEwpAGnlB3D36SQ8ngQCSF6dte2LGvfhL7HEBhiQGnAxu/iv0k+5cPRoRAiiiIYH3QUnPQfPwGgxb+CRsLKhAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6eddc1a5e462ccd261548166cff4e42/e4706/Pushover_08.avif 230w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/d1af7/Pushover_08.avif 460w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/b6582/Pushover_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e6eddc1a5e462ccd261548166cff4e42/a0b58/Pushover_08.webp 230w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/bc10c/Pushover_08.webp 460w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/87ca7/Pushover_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6eddc1a5e462ccd261548166cff4e42/81c8e/Pushover_08.png 230w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/08a84/Pushover_08.png 460w", "/en/static/e6eddc1a5e462ccd261548166cff4e42/e9beb/Pushover_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e6eddc1a5e462ccd261548166cff4e42/e9beb/Pushover_08.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to the Pushover website, click on the logo to go the start page and copy your `}<strong parentName="p">{`User Key`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/e9beb/Pushover_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB4ElEQVQoz4WQTWsTYRDH90t0NxpJG2SJX0K8RgT1ordG8OWkiIiKlPQkItKbEIq3ZDepEP0AtShKxUIjtM9u3syt2NVks8Ru9tltdp+XGUkqvVTwd5oZ+M/856/k8/lrNwqvl4qVJ0/LDx9XS6u1et0wTcOslg2jXKlUDLNimGatVlt7U62t1d++e/Fy5eKl61evXFbOZrOPzl/IZjLp9OlMJqOltFzunK7ruVxO1/XswsKpVEpVVU1Tp8zNaaqWTqfn58+oqqp8azQ6nz5/2Nj4urVFLIvsEttutlqtbrfb+97b3m5sftkkltVstYhlW3bTsm1CyC4htm0rnPO+57nu0Pd9SkPGhRCcMQYAiHgwGnmDQeiPqe/TgEZhckjpIaWCc0RUAEACCM4ppUEQTCYTRITpRADiuN15df/B8mJh5e6957fvPLt5q7hYKBeX2d6eQFRwhpQyiqJxMPY8L45jPgMADkajX/sO2dlZLZXer6//3Hfcfv+358VRBAB/xYAQhmEQBL7vx3EsZgBAwpgECKOo3e4Mh8OpIymFlEerjy+LKIqEkELCMVJK13Udxxm4w4QJPIFyXDHGEMXs36lMSomInE/DS+I4SZKjCP8tllKUP/5YMns9h85awP/xB6904e1F8IeKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/e4706/Pushover_09.avif 230w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/d1af7/Pushover_09.avif 460w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/b6582/Pushover_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/a0b58/Pushover_09.webp 230w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/bc10c/Pushover_09.webp 460w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/87ca7/Pushover_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/81c8e/Pushover_09.png 230w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/08a84/Pushover_09.png 460w", "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/e9beb/Pushover_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1123e3d96a6e321e8ab81a47fdd7fcda/e9beb/Pushover_09.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy the user key into the `}<strong parentName="p">{`Group Key`}</strong>{` field in the Pusherover configuration in ioBroker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bdc4a7d8617385697bf604733b8b94e7/e9beb/Pushover_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABV0lEQVQY013LS0sCUQAF4PlZbfoPvSjaSA/KB6XQIqwMggoULC3aSQVKWamlTpOY4AOJwEwDzddMM4yN4jD3euepixaFLiI6fBzO5mBePLPl9o3Z45Z43xgVVwnJFWvguY9wjonl6fOn1nxY1kel5aGlqGTAJQshrxCyzs9hDs+Nae901pkwR5XFQFd/KxpDwlpEMN+BhauO7pKf8ILJP6Z8YOZiMOb8PDY6bRoZNxmcQXOsH8ySkWwlkKmEsrXrVJkotNM1lK6CVBX+k6zAZ7KLFYpvDFkvMdzZK2B5HvUUqMqopwqy2BY6sgg0EfTkbl9BvzQJahJURYg91rV06zufJ0vx2IM/ZN/cPdix2237HsdxvcG2kdpFCvvJ0cwgNM2wzaYAAIAQQIjhZXRPfb0UGb5QeE8k3daNE9v24bo16DrSeAFJKhIVtslRFEXTNEUOevgd5Ad5pxNGUVoNrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdc4a7d8617385697bf604733b8b94e7/e4706/Pushover_10.avif 230w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/d1af7/Pushover_10.avif 460w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/b6582/Pushover_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bdc4a7d8617385697bf604733b8b94e7/a0b58/Pushover_10.webp 230w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/bc10c/Pushover_10.webp 460w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/87ca7/Pushover_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdc4a7d8617385697bf604733b8b94e7/81c8e/Pushover_10.png 230w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/08a84/Pushover_10.png 460w", "/en/static/bdc4a7d8617385697bf604733b8b94e7/e9beb/Pushover_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bdc4a7d8617385697bf604733b8b94e7/e9beb/Pushover_10.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Test`}</strong>{` to send a test notification to your smartphone:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a23041db53c47eed02b856462e11a78/e9beb/Pushover_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACQUlEQVQoz3WRzW4SURiG5x68Ay/ApTsvQBM3TenC2FJjNSmtCYohJi5YmLggMabRuDBikOBPIvKj1ei0YugEZw4UCsycMz8MDAPCDLRAgAJWIsfMYElb45Nn9eW8eb8vhzh70fo+lrrtfm55nHRsHFyPdG5+2PVGxSCjBOhiCCi3Prasoe5SuHPVdCnSWV3vrKx3L/kU4tTpM8uutQvL9xef0LZwZyWg3wg2bG8bq4HGldf1y359/uVJF0zn/Tpx7vzMrO3uzLU793ybj0h5i4pHY/HNb1Q0Fo9SdDrH77CG6Rw6Ij+ZE71ebzjoY4xhQQ1G6USWZWUlly/kZIXJIZBlEYQIIcGAn8gjNJHwguaL7c7T1MHXdJ7nsg8ers1a5hasi7OWuWA4rKiqJIkcx9E0zQDAMACABMdxyIRg1b283t0bYiSXvie3I58+P/P5va/eeHz+LSbBCpIgCBDCTCaTzZjsZKC5ixH+1Wz9HgwwxkVVpUAykWU5qYBkBcrFFIdSOaNk+po3QYcQ+812v9vDGCuKkgZgh2E4luUhFAWhWCgUCwayLOfzeUmS0HEIvdnuD39ijLXdXfhlQwq84wQBIR4kAEmSG4eQJElR1HSFw+b9/mg0MsKaxkKIzAslSQqFQm632+VyORwOp9Npt9s9Hs/JZozxeDz+G2bZyRRCWKmUa9VqrVbTNK1mommaKIpHy4mxyb/hcrncbrebE1qtH1WtWtNEUTgW/l9zqVSq1+u6iabrilopqZXJt03DfwDP2GpRNyQOvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a23041db53c47eed02b856462e11a78/e4706/Pushover_11.avif 230w", "/en/static/4a23041db53c47eed02b856462e11a78/d1af7/Pushover_11.avif 460w", "/en/static/4a23041db53c47eed02b856462e11a78/b6582/Pushover_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a23041db53c47eed02b856462e11a78/a0b58/Pushover_11.webp 230w", "/en/static/4a23041db53c47eed02b856462e11a78/bc10c/Pushover_11.webp 460w", "/en/static/4a23041db53c47eed02b856462e11a78/87ca7/Pushover_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a23041db53c47eed02b856462e11a78/81c8e/Pushover_11.png 230w", "/en/static/4a23041db53c47eed02b856462e11a78/08a84/Pushover_11.png 460w", "/en/static/4a23041db53c47eed02b856462e11a78/e9beb/Pushover_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a23041db53c47eed02b856462e11a78/e9beb/Pushover_11.png",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The notification should arrive seconds later on your smartphone:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/1a144/Pushover_12.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/9oADAMBAAIQAxAAAAHVohhl/8QAFhAAAwAAAAAAAAAAAAAAAAAAAAIT/9oACAEBAAEFAooQQgh//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AZRKP//EABYRAAMAAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwGmUz//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAYEAADAQEAAAAAAAAAAAAAAAAAAZFhQf/aAAgBAQABPyFO46ZumLp//9oADAMBAAIAAwAAABBz7//EABYRAAMAAAAAAAAAAAAAAAAAAAABYf/aAAgBAwEBPxCKIo//xAAWEQADAAAAAAAAAAAAAAAAAAAAAWH/2gAIAQIBAT8QqyrP/8QAGBAAAgMAAAAAAAAAAAAAAAAAAPEhMWH/2gAIAQEAAT8Qvo9DeOZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/932d659a59e2ef1d659d9ea8e6966ed4/e4706/Pushover_12.avif 230w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/d1af7/Pushover_12.avif 460w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/b6582/Pushover_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/932d659a59e2ef1d659d9ea8e6966ed4/a0b58/Pushover_12.webp 230w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/bc10c/Pushover_12.webp 460w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/87ca7/Pushover_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/932d659a59e2ef1d659d9ea8e6966ed4/e83b4/Pushover_12.jpg 230w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/e41a8/Pushover_12.jpg 460w", "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/1a144/Pushover_12.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/932d659a59e2ef1d659d9ea8e6966ed4/1a144/Pushover_12.jpg",
              "alt": "ioBroker Pushover Integration",
              "title": "ioBroker Pushover Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`We are now able to use an event in ioBroker to send a push notification to our smartphone`}</strong>{`. Check out the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/"
      }}>{`Blockly Tutorial`}</a>{` for more information.`}</p>
    <br /><br /><br /><br />
    <h2 {...{
      "id": "pushsafer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pushsafer",
        "aria-label": "pushsafer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pushsafer`}</h2>
    <p>{`First search for Pushsafer inside your smartphones app store and install the app. Then go to the `}<strong parentName="p">{`Adapters`}</strong>{` tab in ioBroker and install the Pushsafer Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/195d98be464e053b02784a89e181f23d/e9beb/Pushsafer_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACMklEQVQozyXPOW/UQBjGcX8Rer4BLS3Q0EEKQFwVBaKkoKAhLUREQAEFEhG5FEEgx+5GShSJKwUiyVpZ2zMez3jud2btPUIIrI02aZ7ur0e/4Om7lRczny4+eH5rwd5bLu8slfc/2um1cPErmtlOZrbjl5vZtfnu1bnuxFx3YrZ7c7G4vVRcn/eXX5Ng8tX7Z2+Xrzx6M7VlH6+7yZZ70rRTG3x6S7X2VDtVOx25uqsabWi0odmGVuhaoWu27fquCs6cu3T2wt3zNx4eYEZ0Kf1QwIDaYSx6XNl/R4d/j4Y9kL97UILqe13/GRwP/PFhWR0Pg8XZpc8f1hqrG1EURwehUcJZ3es6ZxRK4iiKM0q5ECSj2lhKmbXAuIwQkdoGK5t0oUkbrSSLk1/7+1Eca2PAOalUHMcIY3oS55w75/I8dw4Elwki2tgg6RAjoGe7OFMHUZpirJRyzmmjSZZRSrMsk1JyzgFACAHWKu8zIY2xgS/7VV3XdZXgPIowIZgx5pwzWqdpSgg5jaWU45hzGPTVWpN927FlGRRlv66qUV2Tve/hzx85F957ANBaowTF8distTl9Jghz52yjlW5/0UUR+KKs66qqapomYbiPcco5994bYxBCCCNCiBCCn5i54N6BcB4zbowJXFGORlVdVwTlnU5MKdFKgxs/pxhjhKRUWmvOBQDwfPwvpUwJNdYGZX9wQh7FmQg7CWNMKT02G5MzljMmhDDWKKUA4HTHCiEtwH8uljT3GLy1dwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/195d98be464e053b02784a89e181f23d/e4706/Pushsafer_01.avif 230w", "/en/static/195d98be464e053b02784a89e181f23d/d1af7/Pushsafer_01.avif 460w", "/en/static/195d98be464e053b02784a89e181f23d/b6582/Pushsafer_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/195d98be464e053b02784a89e181f23d/a0b58/Pushsafer_01.webp 230w", "/en/static/195d98be464e053b02784a89e181f23d/bc10c/Pushsafer_01.webp 460w", "/en/static/195d98be464e053b02784a89e181f23d/87ca7/Pushsafer_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/195d98be464e053b02784a89e181f23d/81c8e/Pushsafer_01.png 230w", "/en/static/195d98be464e053b02784a89e181f23d/08a84/Pushsafer_01.png 460w", "/en/static/195d98be464e053b02784a89e181f23d/e9beb/Pushsafer_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/195d98be464e053b02784a89e181f23d/e9beb/Pushsafer_01.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will need a `}<strong parentName="p">{`Private Key`}</strong>{` to use Pushsafer with ioBroker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1b7c6e49669ea011ea2afda1137983b3/e9beb/Pushsafer_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACQklEQVQoz2XOS2vUUBQH8HyMfgFx5WPhZxARulNxUZCCdlHF1qIrQUSorl0JKvioFYrTWvqwVqkWpFon80prJ5nJO5lkmtx77iO5Mx0tEkmlOOCPP+eczR+OdPz0xdcrGw8evxm8W5hYFePL9MZKMrlszX/V3snmctFYLZu3V2FkgV9dZKOLbGKF33zPx5fIpWlXGjh66s7DF9cmn1y8Pz+21B2eIVcKbGSWjM7B9bdkeAaGpuMLU/jcy8NM4fOv8OAzfGsBSQNHTp44M3Ts7OWxe4+eFzbWi83PZf1TSf8o62tl40NRLxqkZJKSCbIBJSOfsgHfddB8IsmyrOt62PIs2346v1bdUeuGodr2jmFqtq1aVhjHnOKEQSehIiF5OBWcMArShiW+mGIzyJSg88MwtwxHD2MHcxtRBzEbMRcRyngUI8fxXC/wvNBxPb8VIIylQo3MbfHZnd56gwaeAxhTgIRRTv+FMdZuh5raqKtBXQ20hum6LgBIv6L2b0qyLOvu7W2q+re6Xmzaih8p/q7i79a83XoQIYQAgHPOGGWM5ovSvEzzO82yLEmFZVlBEERRJIRI+wghAKDl++GBIAijOCYAEhcdnogsy9I01VQNISQ6HdEnTdNutxu225VyZXt7S1VVy7I9z8MYSxEiPM3LQohmsxnFMQCwPpTSJElafqtSrtRqNUVRqtWqZVr523u9n/v7+1mWdYRoNBreAUopIQRjDIcwxij3dyJCSF7ODvV6PYQQZ5xSyjmH/5CD9PsDOmRjO3cel/cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b7c6e49669ea011ea2afda1137983b3/e4706/Pushsafer_02.avif 230w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/d1af7/Pushsafer_02.avif 460w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/b6582/Pushsafer_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1b7c6e49669ea011ea2afda1137983b3/a0b58/Pushsafer_02.webp 230w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/bc10c/Pushsafer_02.webp 460w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/87ca7/Pushsafer_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b7c6e49669ea011ea2afda1137983b3/81c8e/Pushsafer_02.png 230w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/08a84/Pushsafer_02.png 460w", "/en/static/1b7c6e49669ea011ea2afda1137983b3/e9beb/Pushsafer_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1b7c6e49669ea011ea2afda1137983b3/e9beb/Pushsafer_02.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For this create an account on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://www.pushsafer.com`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d1a233252b352b535240c9ff5f15372/e9beb/Pushsafer_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+ElEQVQoz43Qz06DQBDHcd6x8WD6Ch76GsamxnPTdzFqYoT2UM/awgLLnwUJ3dmdWUTWtLVREjV88j3O7zLO+Xh8NhpdTCa388Xj9PphdnM/nd1dXrnzxWq9dl3vqZ/rLb3l6pizDYLAD8KQZWnu+1EUcsZ4HCX7UlHvJCjVCxScOICakIioBnx+iTe83PLiNS5ZVm0iodBYa7vO/sqppQQAKaUxpnorDKE9nncf1nZt277/7WsMAIg6iuOiKDWiUlrpff/rjVnIdlI2TWOIzAA/x5gkiRACD/QA32OtdZKmnHMAMMY0A5weBkBEWZbleS6EqKpKDfAJwInzVI81u5wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d1a233252b352b535240c9ff5f15372/e4706/Pushsafer_03.avif 230w", "/en/static/3d1a233252b352b535240c9ff5f15372/d1af7/Pushsafer_03.avif 460w", "/en/static/3d1a233252b352b535240c9ff5f15372/b6582/Pushsafer_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d1a233252b352b535240c9ff5f15372/a0b58/Pushsafer_03.webp 230w", "/en/static/3d1a233252b352b535240c9ff5f15372/bc10c/Pushsafer_03.webp 460w", "/en/static/3d1a233252b352b535240c9ff5f15372/87ca7/Pushsafer_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d1a233252b352b535240c9ff5f15372/81c8e/Pushsafer_03.png 230w", "/en/static/3d1a233252b352b535240c9ff5f15372/08a84/Pushsafer_03.png 460w", "/en/static/3d1a233252b352b535240c9ff5f15372/e9beb/Pushsafer_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d1a233252b352b535240c9ff5f15372/e9beb/Pushsafer_03.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And copy your `}<strong parentName="p">{`Private Key`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/11689bf5c55335e159b469c1a0607658/e9beb/Pushsafer_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAChklEQVQoz02Ra0/TABiF+wf8C/4cP/kD/GD8YDQxMUaCZEKIxKgxKsEQJGhUErwLXqMREBEH3nDA1m3sQsc6KF13bbuubG23wWOsfvAkJ+973vPhvMkRAn19BM6fZ35mmmR6g7VojNW1MKthkTUxRnpDYkPKkEz/nVImQ2pD8vlHb2Zlvix+I9B7jYGBCwhYBm4xz+TLOWxVoV0t0fJc/mKfpuOw32n7qt3ycBzHv8MezabDXqfle3t7Hq7rIchbGnqhQCSVw7EsOrZFtarz+KPI26UkLdchnFIYnlpGVis0Grs8nYvyejGJ5zrEJJXRVyHSWyVs20ZQzQatTof/oWhlDgWmOH79A+DwcFbkwOER4rmq758YnOHixFeg5YcePHKXj78k9lsOgh4KsfUlyM63HzQqVZquQ7FUYWjyJ0OTy6wkFR5Nhzl14x3P5iIsrEhcnlji+pPv/v7sU5yesc88nAmTljWExNv3LD9/QWRyip31BIVyGTVfYPjlKt1jC3SNLXByLMjp20FOjwY5dStIz/0fdN1Z4ti1aY4PznJmZJ6jVz8wtZBAEMMiq2KcSFQkt72NpuXRtBIlW0OpZ1CtDLU3j1l59YDeK30MXOol0H+OkXs30RoyeXsTxcqQ393EtA2ErLxNNBZnfX2dQqHot1iu6Bh1nZKZp2jk0TcTGIUckiKRVSSSUpyEFKVoqNQcnbprYnsm1q6NUDXrVCpVajWL9r9yDLNO3lBIqhGylRSzoTnO9nYxcKWfQH8348/HEXfSfE+GyBkZ1HrWp143EEplHVmWMQwDTSvgeS0M08Jq1rA8Hbtd8z+NJsLEUiJiIkx2J4PpGBiOjulUfVqujrVb5zdAQLK9P6x0HwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11689bf5c55335e159b469c1a0607658/e4706/Pushsafer_04.avif 230w", "/en/static/11689bf5c55335e159b469c1a0607658/d1af7/Pushsafer_04.avif 460w", "/en/static/11689bf5c55335e159b469c1a0607658/b6582/Pushsafer_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/11689bf5c55335e159b469c1a0607658/a0b58/Pushsafer_04.webp 230w", "/en/static/11689bf5c55335e159b469c1a0607658/bc10c/Pushsafer_04.webp 460w", "/en/static/11689bf5c55335e159b469c1a0607658/87ca7/Pushsafer_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11689bf5c55335e159b469c1a0607658/81c8e/Pushsafer_04.png 230w", "/en/static/11689bf5c55335e159b469c1a0607658/08a84/Pushsafer_04.png 460w", "/en/static/11689bf5c55335e159b469c1a0607658/e9beb/Pushsafer_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/11689bf5c55335e159b469c1a0607658/e9beb/Pushsafer_04.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Paste it into the adapter configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d770f99352dbe818d977a357a2bd10b/e9beb/Pushsafer_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7EAAAOxAGVKw4bAAACq0lEQVQoz32QW2scZRiA50944R/opVL8C14VvfBQelOLIgS0oA32ygt70db73lVEPFSFtluDNWmWZk2htHEz2WR3bfY4k+3OeWdn5ptvDtmuTeGRmdQSQXzhgZfv433eg/LK66f4ceUBX351gzcvVPismnNuWbC4mnJ5ZcQvD/usbO2xrOqsNvb4vBqy8Kvk7O2Yj27HLN6RnK9KFn+LOflzB+WlY6/xxZXvOXvpa05dXuLT5RnvX4/48GbMQiXi41sRnyxFfHA95PRPU05eC3j7h5B3jvDutYC3vhW8d2uM8vKx4xw/cYZX31jg3MWrfFfZ4J46ZL2hUVOHrKkav29p3FU1VE2g6hGqFrF5hLoWsq3HVIcmys72DrquMXEsxsaYb5ZqPOr3MScedjDFcF20qc/AdfGmE3IZkicRsyxmloqS/VTwLJfsegbKxnjGw8f7qB60nX16oxGbj7pUqjWW1u6xuqGi1e6zt9nEEAlxLJn4Ux6PDQzDxjTtMvddjx3bQLnZFFTaCTd256z3BZ5lYlsm67U11tfu8ke9jlhZJanXSfIMGce4rkuv22PQ1Rl09+j1BrimRdMxUA4mHsSCIp7+9YSNzpDNvs6u7aOLnGEg+TNKafmCruURBAFRFJEkCVLKklhKcpkcCqVMSZKsFKZZxmg0wnEcPM8jTZOyMHtO8Z9lGWEYYts2ruuV09qOQxyEtFwTJc33kWleCrMspdfv4ft+WZjn+b8o3mazWdmwsdWg3W7R6XTQdB2vWLm4oR9EJM+FeZYxHA4xLavsXK4TxyX/5GmaYls2241t2q0W7XabnVYTe2zQdEyUJ/M5BwfPDoV5zqA/wLYsTNNkMpkghHhxt2LV/yIIA3IpD2/IkZjP52VxMU0hKiYqCgrZ/xGEIakQpfBvG8JL3uiAZB8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d770f99352dbe818d977a357a2bd10b/e4706/Pushsafer_05.avif 230w", "/en/static/4d770f99352dbe818d977a357a2bd10b/d1af7/Pushsafer_05.avif 460w", "/en/static/4d770f99352dbe818d977a357a2bd10b/b6582/Pushsafer_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d770f99352dbe818d977a357a2bd10b/a0b58/Pushsafer_05.webp 230w", "/en/static/4d770f99352dbe818d977a357a2bd10b/bc10c/Pushsafer_05.webp 460w", "/en/static/4d770f99352dbe818d977a357a2bd10b/87ca7/Pushsafer_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d770f99352dbe818d977a357a2bd10b/81c8e/Pushsafer_05.png 230w", "/en/static/4d770f99352dbe818d977a357a2bd10b/08a84/Pushsafer_05.png 460w", "/en/static/4d770f99352dbe818d977a357a2bd10b/e9beb/Pushsafer_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d770f99352dbe818d977a357a2bd10b/e9beb/Pushsafer_05.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the app and login with the account you just created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b6729ac866e3db9bd7dc1116352173fa/1a144/Pushsafer_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7DkbNBsK/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAITAQMS/9oACAEBAAEFApqTQlhfS+nNva//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BoX//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BhX//xAAZEAADAQEBAAAAAAAAAAAAAAAAATGREUH/2gAIAQEABj8CiwiPMIiI6f/EABoQAAMBAAMAAAAAAAAAAAAAAAABESEQcfH/2gAIAQEAAT8hQzyh0yF1I8HPqU0//9oADAMBAAIAAwAAABDsD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBS6//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBWY//EABwQAQADAAIDAAAAAAAAAAAAAAEAEdFBUZHB4f/aAAgBAQABPxBN34x6gGXIxvWFlRYhULy7H7jsqGViif/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6729ac866e3db9bd7dc1116352173fa/e4706/Pushsafer_06.avif 230w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/d1af7/Pushsafer_06.avif 460w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/b6582/Pushsafer_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b6729ac866e3db9bd7dc1116352173fa/a0b58/Pushsafer_06.webp 230w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/bc10c/Pushsafer_06.webp 460w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/87ca7/Pushsafer_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6729ac866e3db9bd7dc1116352173fa/e83b4/Pushsafer_06.jpg 230w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/e41a8/Pushsafer_06.jpg 460w", "/en/static/b6729ac866e3db9bd7dc1116352173fa/1a144/Pushsafer_06.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b6729ac866e3db9bd7dc1116352173fa/1a144/Pushsafer_06.jpg",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assign a name and register your phone:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/1a144/Pushsafer_07.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAAB0VhRUj//xAAbEAACAQUAAAAAAAAAAAAAAAAAAhMBAxARMf/aAAgBAQABBQKNiNhbdNYXn//EABURAQEAAAAAAAAAAAAAAAAAABAS/9oACAEDAQE/AYP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEv/aAAgBAgEBPwGj/8QAHhAAAQMEAwAAAAAAAAAAAAAAAAECAxAxMpERImH/2gAIAQEABj8CwjMIzs1vPhddl13T/8QAHRAAAQMFAQAAAAAAAAAAAAAAABHh8AExYXHxgf/aAAgBAQABPyGGxDYsAwCdAnQbPan/2gAMAwEAAgADAAAAELjP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxCNGo4f/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIXH/2gAIAQIBAT8QyJzZ/8QAHhABAAIBBAMAAAAAAAAAAAAAAQARITFBgfCRodH/2gAIAQEAAT8QbMcqwH9WH6+aElckc3rcxz63mGq2s7i+5//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e075dc7bec1dc5bb4f843583caa5a32/e4706/Pushsafer_07.avif 230w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/d1af7/Pushsafer_07.avif 460w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/b6582/Pushsafer_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2e075dc7bec1dc5bb4f843583caa5a32/a0b58/Pushsafer_07.webp 230w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/bc10c/Pushsafer_07.webp 460w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/87ca7/Pushsafer_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e075dc7bec1dc5bb4f843583caa5a32/e83b4/Pushsafer_07.jpg 230w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/e41a8/Pushsafer_07.jpg 460w", "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/1a144/Pushsafer_07.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2e075dc7bec1dc5bb4f843583caa5a32/1a144/Pushsafer_07.jpg",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6a1942e1aec1706c541255b8c466ab4/1a144/Pushsafer_08.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB1wUH/8QAFhAAAwAAAAAAAAAAAAAAAAAAAAIT/9oACAEBAAEFApKSQmh//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAExkTL/2gAIAQEABj8Ciw5WER//xAAYEAADAQEAAAAAAAAAAAAAAAAAAfFRgf/aAAgBAQABPyHV3BAEpH//2gAMAwEAAgADAAAAEHAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRAAAwADAAAAAAAAAAAAAAAAAAHwgaHB/9oACAEBAAE/EEiKwJMmiK4f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a1942e1aec1706c541255b8c466ab4/e4706/Pushsafer_08.avif 230w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/d1af7/Pushsafer_08.avif 460w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/b6582/Pushsafer_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6a1942e1aec1706c541255b8c466ab4/a0b58/Pushsafer_08.webp 230w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/bc10c/Pushsafer_08.webp 460w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/87ca7/Pushsafer_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a1942e1aec1706c541255b8c466ab4/e83b4/Pushsafer_08.jpg 230w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/e41a8/Pushsafer_08.jpg 460w", "/en/static/a6a1942e1aec1706c541255b8c466ab4/1a144/Pushsafer_08.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6a1942e1aec1706c541255b8c466ab4/1a144/Pushsafer_08.jpg",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back to Pushsafer website you will now see your smartphone listed. Copy the auto assigned ID:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/e9beb/Pushsafer_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABn0lEQVQY042QXWvTUByH8yWFzRvxwo/g9XBjOMFLcaBMuwhamOgmKpYQVnWTtbSrTZvSk5cmpE2a5OTlJNrM9Lz8ZXrnlQ/PxXP54yc9bxw2nh2cqapt28gwkWFMDROZljVzbMf9R2vmup7XUtpPD2RZPpRgVV5Fy5dv2+vQF0nIGIP/QwghafYSxzglJa1rVv9y5uHdffXOw4+3995v3Dve2D7e3D7Z3Ln2b9/cObmx9ebW/Xdo5ktlTq5+lJQxAcA5j1Py+dJUvumnPUvpoB6Ku9OwO/HVLjrt2eeadzZ0v44WnUmwxJnkjHX9cjDTdYJxkqY4Sa8XcQr1SvB6XcXAa8BzTpYV/ORVDmUqKszqIs1L6bvavvjwadBqedp44QdhjKM4/7L/pFjGTuweDZsJSWhFUYAedR6jwKhwhPpHfa9XFpWEJtPBaDwcaYsgiKIoSrIyy4vdvVWMy6paU8o5AxB/DuIgBKWM5AWjLM0LyfMj07Js2yaEcMbTjHDOgXMA4EIAAJpnW43zB68udl90mv3Wa9SUNVlxlCQjvwFab5t4hoeh9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/e4706/Pushsafer_09.avif 230w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/d1af7/Pushsafer_09.avif 460w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/b6582/Pushsafer_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/a0b58/Pushsafer_09.webp 230w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/bc10c/Pushsafer_09.webp 460w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/87ca7/Pushsafer_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/81c8e/Pushsafer_09.png 230w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/08a84/Pushsafer_09.png 460w", "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/e9beb/Pushsafer_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3a2f17a1c7f0224466dd50e3ff17e57e/e9beb/Pushsafer_09.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And copy it into the Device field. Clicking the `}<strong parentName="p">{`Test Button`}</strong>{` should now send a test notification to your smartphone:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a79523c05f93f622abaf59bd7c95dee2/e9beb/Pushsafer_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACaElEQVQoz02Oy2oTYRSA5y30AQRXXjY+giCiGy9QUFARFVFKrbpy4QWsD+BCBEVsyaKYkJCY1jTUuNCUJs21pTOZ6Uxm/rlkkv8+M8mkDXYkKVU/Ps7uO+cIp85OxZZ/vXn/5eLz+ONc8ChLZpe9uayaKopLZXWppKxU1Gc5fC/NH2bYgwybXWZPv/GZLL4RM4Sjx8+8eDs/Pfdhai41/TW8uYhvf2F34+R+Ak8n8a1FdC3Wu7oAL82jy4deWUAXPqEnaSgcOXb65LnrJ87fmXn17nOiWCjJq2U5X5JzJXm1ouXK6rpCSjuktIP/qeA1BYuACOXyhqIotgm0dvtjcrUptcxu10bIcN2Wrkua5sCeR5HPcN+jwYGcBpwwioWi5v/UgnVnv2EHotYu1rcWEulYeimZL6hd0obM6BHKeLcHdQMAYAFg6wYwLRsiJMRrJNHg8a1hQSIdy9TbWiaVyiST3/MrBCGPUU4JY8xxHEmUZFGVRa0lyYZhYIyFPdf9TUkURYMwXNuWq4quYN7e3Ve9sGm6DeCKdhdBSAhmnDPGKKOMM0rpOKaUc+5HUeR5vmmCtdJGMV+gP4rdbYX6IWUB5X3qDXqQWJbpTLBtp9frjWMv6HMvmMSeIssteUeXZL++yXTgBwPfCzwv6PdD2+lUK9XNzaYoiprWBgAghIQuJNwfx0EQKIrS6XQgRny0x4Yhp3TyKPU9z7KsykalXqs3G41qraaq6vhyONwdjUZRFPWDoCW1gAF03XANgzgdhBA+BCEExxxMSAgZx9Ehw+EQQsgpJYMBTWfxy9fYdTEh+O8KMvE//gDTiFwRe87zcwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a79523c05f93f622abaf59bd7c95dee2/e4706/Pushsafer_10.avif 230w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/d1af7/Pushsafer_10.avif 460w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/b6582/Pushsafer_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a79523c05f93f622abaf59bd7c95dee2/a0b58/Pushsafer_10.webp 230w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/bc10c/Pushsafer_10.webp 460w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/87ca7/Pushsafer_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a79523c05f93f622abaf59bd7c95dee2/81c8e/Pushsafer_10.png 230w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/08a84/Pushsafer_10.png 460w", "/en/static/a79523c05f93f622abaf59bd7c95dee2/e9beb/Pushsafer_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a79523c05f93f622abaf59bd7c95dee2/e9beb/Pushsafer_10.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b210ab4f373ee48a8428761f18cc7a2/e9beb/Pushsafer_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACUklEQVQoz02Pz0/TYBzGe/Iv8O7FgzHevRujN2OUGI0eTAg6p4iaoAmKJh64eJBghonIQWABdZMMJtsYI4tbu75d2bq+b99ubGvZxn7VbX3b8UPCodoREj/5HL7fw5M8D3XuQt+UNzLyfvbK28Wnob1BP3kc2BkLbC0mNv286ueVUEodCXUGfF3nsvlg2RxaMZ8FzKGfxh13mTp9/vJnT+jVh/m+sWWnf+/ut06/lzg92nNf/aW/+eRHY/B77Za7dW2mfX3W9oa7fdPdvjrTdnga1ImTp85evH3mUv/Am08TXsHllz4GZVcgO7GSm1jJvVvKjQdVV1iZPNa1qkyuKuPB4iKoUF/m5peCkUA4Go3R03OeFEdnUgkxDTIbrJgGMA1KBalSkMp5VOkdleN3W92ktslhzTjcsaw66YZpEIolwkwyAlJrLL/G8uEEH0kkRQgljEUIIUIitM2IMJ/PU9NJMsUZLm7fu9HIwszXhYXXo6Mvhocd9+85HQ6nw/HoodPn82GM47E4Ewe2NMNxnB3WK9Xdjm5ZFjHMVZoVZXkzl5NtsnJWRghJGCuKIkmSjGV8pCxjjAuFArWnk32za4cJoVlQrpRb7ZamaS2b301NazS1Wq0uHYGxJEkIIQihHSbdXdLdsSxL13U6Hq9Wq4SQTg9d1xvNVkHdrjeaoijGYzGGYQAAKZu0XbutG4Zph4mucwBgjIvFYqlU2voPdUsVBGE9sh6NRn/1YFm2t9kw/xwcHNVmGIbn+STPQwgxxvbg47YIIUFICz3SgoAQ+lf7L43bANnwETDiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b210ab4f373ee48a8428761f18cc7a2/e4706/Pushsafer_11.avif 230w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/d1af7/Pushsafer_11.avif 460w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/b6582/Pushsafer_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b210ab4f373ee48a8428761f18cc7a2/a0b58/Pushsafer_11.webp 230w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/bc10c/Pushsafer_11.webp 460w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/87ca7/Pushsafer_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b210ab4f373ee48a8428761f18cc7a2/81c8e/Pushsafer_11.png 230w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/08a84/Pushsafer_11.png 460w", "/en/static/0b210ab4f373ee48a8428761f18cc7a2/e9beb/Pushsafer_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b210ab4f373ee48a8428761f18cc7a2/e9beb/Pushsafer_11.png",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be63580b68a9ab63899b73a40c5b28f8/1a144/Pushsafer_12.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGxmhngn//EABkQAAEFAAAAAAAAAAAAAAAAAAABAwQjM//aAAgBAQABBQKLiPpd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAECEP/aAAgBAQAGPwJZR//EABsQAQAABwAAAAAAAAAAAAAAAAEAEBExQWGx/9oACAEBAAE/Ibfb2QK0zH//2gAMAwEAAgADAAAAEAP/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAEWExUf/aAAgBAQABPxCgIK8NRBESzYnArfmE/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be63580b68a9ab63899b73a40c5b28f8/e4706/Pushsafer_12.avif 230w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/d1af7/Pushsafer_12.avif 460w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/b6582/Pushsafer_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be63580b68a9ab63899b73a40c5b28f8/a0b58/Pushsafer_12.webp 230w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/bc10c/Pushsafer_12.webp 460w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/87ca7/Pushsafer_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be63580b68a9ab63899b73a40c5b28f8/e83b4/Pushsafer_12.jpg 230w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/e41a8/Pushsafer_12.jpg 460w", "/en/static/be63580b68a9ab63899b73a40c5b28f8/1a144/Pushsafer_12.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be63580b68a9ab63899b73a40c5b28f8/1a144/Pushsafer_12.jpg",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fef62c876adeff86fe3938e589ebaed5/1a144/Pushsafer_13.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAetthJYf/8QAGBABAAMBAAAAAAAAAAAAAAAAAgABEyD/2gAIAQEAAQUCoGZiZnj/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAATEg/9oACAEBAAY/AoiIix//xAAaEAACAgMAAAAAAAAAAAAAAAAAARDxETGR/9oACAEBAAE/IW98kUSKZGJ//9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB8QAAIBAgcAAAAAAAAAAAAAAAABMRDwESFhcaHB0f/aAAgBAQABPxBBmHr4iRecFgdGBQktqTJ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fef62c876adeff86fe3938e589ebaed5/e4706/Pushsafer_13.avif 230w", "/en/static/fef62c876adeff86fe3938e589ebaed5/d1af7/Pushsafer_13.avif 460w", "/en/static/fef62c876adeff86fe3938e589ebaed5/b6582/Pushsafer_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fef62c876adeff86fe3938e589ebaed5/a0b58/Pushsafer_13.webp 230w", "/en/static/fef62c876adeff86fe3938e589ebaed5/bc10c/Pushsafer_13.webp 460w", "/en/static/fef62c876adeff86fe3938e589ebaed5/87ca7/Pushsafer_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fef62c876adeff86fe3938e589ebaed5/e83b4/Pushsafer_13.jpg 230w", "/en/static/fef62c876adeff86fe3938e589ebaed5/e41a8/Pushsafer_13.jpg 460w", "/en/static/fef62c876adeff86fe3938e589ebaed5/1a144/Pushsafer_13.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fef62c876adeff86fe3938e589ebaed5/1a144/Pushsafer_13.jpg",
              "alt": "ioBroker Pushsafer Integration",
              "title": "ioBroker Pushsafer Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`We are now able to use an event in ioBroker to send a push notification to our smartphone`}</strong>{`. Check out the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/"
      }}>{`Blockly Tutorial`}</a>{` for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      